import React from "react";
import BackButton from "../../components/button/BackButton";
import { VStack } from "@chakra-ui/react";

const Profile = () => {
  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <BackButton />
    </VStack>
  );
};

export default Profile;
