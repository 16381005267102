import React from "react";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";

const DeleteModal = ({ deleteHandler, isDisable, setShowModal, showModal }) => {
  return (
    <Modal
      isCentered
      onClose={!showModal}
      isOpen={showModal}
      motionPreset="scale"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w={{ base: "xs", md: "sm" }}>
        <ModalHeader>Устгах уу?</ModalHeader>
        {/* <ModalCloseButton
          //   onClick={setShowModal(false)}
          _focusVisible={{ outline: "none" }}
        /> */}
        <ModalFooter>
          <Button
            colorScheme="gray"
            mr={3}
            px={"8"}
            _focusVisible={{
              outline: "none",
            }}
            onClick={() => setShowModal(false)}
          >
            Үгүй
          </Button>
          <Button
            colorScheme="red"
            px={"8"}
            _focusVisible={{
              outline: "none",
            }}
            onClick={deleteHandler}
            isDisabled={isDisable}
          >
            {isDisable ? <Spinner mr={2} size="sm" /> : null}
            Тийм
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
