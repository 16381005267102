import React from "react";
import {
  Flex,
  Text,
  IconButton,
  VStack,
  Stack,
  HStack,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
} from "@chakra-ui/react";
import { navData } from "./SidebarData";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RiMenu4Line } from "react-icons/ri";

export default function Sidebar() {
  const [open, cycleOpen] = useCycle(true, false);
  const navigate = useNavigate();

  const location = useLocation();
  // console.log("location ---->", location);

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };

  return (
    <Flex
      h="100vh"
      // boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      flexDir="column"
      justifyContent="space-between"
      borderRightWidth={"thin"}
      borderColor={"#eee"}
      className="primaryColor"
      zIndex={999}
      minW={"16"}
      initial={{ width: 200 }}
      animate={{ width: open ? 200 : 100 }}
      transition={"ease-in-out .8s"}
    >
      <HStack w={"full"} h={"16"} pl={"2.5"}>
        <IconButton
          background="none"
          _hover={{ background: "none" }}
          icon={<RiMenu4Line />}
          onClick={cycleOpen}
        />
        <AnimatePresence>
          {open && (
            <HStack
              initial={{
                width: 0,
              }}
              animate={{
                width: 200,
              }}
              exit={{
                width: 0,
                // transition: { delay: 0.1, duration: 0.3 },
              }}
              as={motion.div}
            >
              <HStack
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
                as={motion.div}
                onClick={() => navigate("/")}
                cursor={"pointer"}
              >
                <Text
                  as={motion.a}
                  variants={itemVariants}
                  fontWeight={"bold"}
                  textTransform={"uppercase"}
                >
                  Admin
                  <Text
                    as={"span"}
                    color={"#0066CC"}
                    fontWeight={"bold"}
                    textTransform={"uppercase"}
                  >
                    panel
                  </Text>
                </Text>
              </HStack>
            </HStack>
          )}
        </AnimatePresence>
      </HStack>

      <VStack
        w={"250"}
        h={"full"}
        mr={"2"}
        align={"center"}
        justify={"flex-start"}
      >
        <Accordion allowMultiple w="100%">
          <Stack w={"full"} spacing={2}>
            {navData.map((e, index) =>
              e.subTitle ? (
                !open ? (
                  <Popover placement="right-start" isLazy>
                    {({ isOpen, onClose }) => (
                      <>
                        <PopoverTrigger>
                          <HStack
                            transition={"ease-in-out .3s"}
                            key={e.title}
                            py={"1"}
                            px={open ? "1.5" : "3"}
                            pl={"5"}
                            w={"100%"}
                            minW={"8"}
                            minH={"10"}
                            bg={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "#F5F5F5"
                                : "transparent"
                            }
                            borderRightRadius={"full"}
                            _hover={{
                              bg: "#eee",
                            }}
                            cursor={"pointer"}
                          >
                            <Icon
                              as={e.icon}
                              boxSize={"4"}
                              color={
                                location.pathname.split("/")[1] ===
                                e.toLink.split("/")[1]
                                  ? "#0066CC"
                                  : "gray.700"
                              }
                            />
                          </HStack>
                        </PopoverTrigger>
                        <PopoverContent
                          w="fit-content"
                          _focus={{ boxShadow: "none" }}
                        >
                          <PopoverArrow />
                          <PopoverBody>
                            <Stack spacing={0}>
                              {e.subTitle.map((a, i) => (
                                <Button
                                  key={i}
                                  as={NavLink}
                                  to={a.toLink}
                                  w="194px"
                                  variant="ghost"
                                  justifyContent="space-between"
                                  fontSize="sm"
                                  onClick={() => {
                                    onClose();
                                    navigate("/profile");
                                  }}
                                  _hover={{
                                    bg: "rgba(0,0,0,.03)",
                                  }}
                                  fontWeight={
                                    location.pathname.split("/")[2] ===
                                    a.toLink.split("/")[2]
                                      ? "medium"
                                      : "normal"
                                  }
                                  color={
                                    location.pathname.split("/")[2] ===
                                    a.toLink.split("/")[2]
                                      ? "#0066CC"
                                      : "#000"
                                  }
                                >
                                  {a.title}
                                </Button>
                              ))}
                            </Stack>
                          </PopoverBody>
                        </PopoverContent>
                      </>
                    )}
                  </Popover>
                ) : (
                  <AccordionItem key={index} border="none">
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          transition={"ease-in-out .3s"}
                          key={e.title}
                          py={"1"}
                          px={open ? "1.5" : "3"}
                          pl={"5"}
                          pr={"3"}
                          w={"100%"}
                          minW={"8"}
                          minH={"10"}
                          bg={
                            location.pathname.split("/")[1] ===
                            e.toLink.split("/")[1]
                              ? "#F5F5F5"
                              : "transparent"
                          }
                          borderRightRadius={"full"}
                          _hover={{
                            bg: "#eee",
                          }}
                          cursor={"pointer"}
                        >
                          <HStack
                            w={"full"}
                            align="center"
                            cursor="pointer"
                            as="span"
                            flex="1"
                            textAlign="left"
                          >
                            <Icon as={e.icon} />
                            <AnimatePresence>
                              {open && (
                                <Stack
                                  initial={{ width: 0 }}
                                  animate={{
                                    width: 100,
                                  }}
                                  exit={{
                                    width: 0,
                                    transition: { delay: 0.1, duration: 0.3 },
                                  }}
                                  as={motion.div}
                                  w={"full"}
                                  h={"full"}
                                  align={"flex-start"}
                                  justify={"flex-start"}
                                >
                                  <HStack
                                    initial="closed"
                                    animate="open"
                                    exit="closed"
                                    variants={sideVariants}
                                    as={motion.div}
                                    w={"100"}
                                    h={"full"}
                                    align={"center"}
                                    justify={"space-between"}
                                    // bg={"red"}
                                  >
                                    <Text
                                      as={motion.a}
                                      // whileHover={{ scale: 1.1 }}
                                      variants={itemVariants}
                                      noOfLines={1}
                                      fontSize={15}
                                      fontWeight={
                                        e.toLink === location.pathname
                                          ? "medium"
                                          : "normal"
                                      }
                                      color={
                                        e.toLink === location.pathname
                                          ? "#0066CC"
                                          : "#000"
                                      }
                                    >
                                      {e.title}
                                    </Text>
                                  </HStack>
                                </Stack>
                              )}
                            </AnimatePresence>
                          </HStack>
                          <AccordionIcon display={open ? "block" : "none"} />
                        </AccordionButton>
                        <AccordionPanel p={0} my={2} mx={0}>
                          <VStack
                            align="center"
                            cursor="pointer"
                            ml={"27px"}
                            borderLeftWidth={"2px"}
                            borderColor={"#F5F5F5"}
                          >
                            {e.subTitle.map((a, i) => (
                              <HStack
                                transition="ease-in-out .3s"
                                key={i}
                                as={NavLink}
                                to={a.toLink}
                                alignSelf="flex-start"
                                align={"center"}
                                w={"100%"}
                                p={2}
                                pl={"4"}
                                _hover={{
                                  color: "#0066CC",
                                }}
                              >
                                <Text
                                  w={"full"}
                                  fontSize={14}
                                  fontWeight={
                                    location.pathname.split("/")[2] ===
                                    a.toLink.split("/")[2]
                                      ? "medium"
                                      : "normal"
                                  }
                                  color={
                                    location.pathname.split("/")[2] ===
                                    a.toLink.split("/")[2]
                                      ? "#0066CC"
                                      : "#000"
                                  }
                                >
                                  {a.title}
                                </Text>
                              </HStack>
                            ))}
                          </VStack>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                )
              ) : (
                <HStack
                  transition={"ease-in-out .3s"}
                  key={e.title}
                  py={"1"}
                  px={open ? "1.5" : "3"}
                  pl={"5"}
                  w={"100%"}
                  minW={"8"}
                  minH={"10"}
                  bg={
                    location.pathname.split("/")[1] === e.toLink.split("/")[1]
                      ? "#F5F5F5"
                      : "transparent"
                  }
                  borderRightRadius={"full"}
                  _hover={{
                    bg: "#eee",
                  }}
                  cursor={"pointer"}
                  onClick={() => navigate(e.toLink)}
                >
                  <Icon
                    as={e.icon}
                    boxSize={"4"}
                    color={
                      location.pathname.split("/")[1] === e.toLink.split("/")[1]
                        ? "#0066CC"
                        : "gray.600"
                    }
                  />
                  <AnimatePresence>
                    {open && (
                      <Stack
                        initial={{ width: 0 }}
                        animate={{
                          width: 200,
                        }}
                        exit={{
                          width: 0,
                          transition: { delay: 0.1, duration: 0.3 },
                        }}
                        as={motion.div}
                        w={"full"}
                        h={"full"}
                        align={"flex-start"}
                        justify={"flex-start"}
                      >
                        <VStack
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={sideVariants}
                          as={motion.div}
                          w={"250"}
                          h={"full"}
                          align={"flex-start"}
                          justify={"center"}
                        >
                          <Text
                            as={motion.a}
                            // whileHover={{ scale: 1.1 }}
                            variants={itemVariants}
                            noOfLines={1}
                            fontSize={15}
                            fontWeight={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "medium"
                                : "normal"
                            }
                            color={
                              location.pathname.split("/")[1] ===
                              e.toLink.split("/")[1]
                                ? "#0066CC"
                                : "#000"
                            }
                          >
                            {e.title}
                          </Text>
                        </VStack>
                      </Stack>
                    )}
                  </AnimatePresence>
                </HStack>
              )
            )}
          </Stack>
        </Accordion>
      </VStack>
    </Flex>
  );
}
