import React from "react";
import { Select, Text, VStack } from "@chakra-ui/react";

const CustomSelect = ({ title, value, onChange, options }) => {
  return (
    <VStack w={"full"} align="flex-start" justify={"flex-start"} spacing={0}>
      <Text fontWeight={"medium"}>{title + ":"}</Text>
      <Select
        w={"full"}
        // size={"sm"}
        // rounded={"none"}
        fontWeight={"normal"}
        fontSize={15}
        color={"#000"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        _focusVisible={{
          outline: "none",
          borderColor: "#0066CC",
        }}
        // _hover={{
        //   outline: "none",
        // }}
      >
        <option selected hidden disabled value="">
          -
        </option>
        {options
          ? options.map((e) => (
              <option key={e.name} value={e.name}>
                {e.name}
              </option>
            ))
          : null}
      </Select>
    </VStack>
  );
};

export default CustomSelect;
