import React, { useEffect, useState } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import BackButton from "../../../components/button/BackButton";
import { useLocation } from "react-router-dom";
import axios from "../../../utils/axios";
import Cookies from "js-cookie";
import DetailTable from "./DetailTable";
import QRCode from "react-qr-code";

const InvoiceDetail = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(true);
  const location = useLocation();
  const { state } = location;
  const token = Cookies.get("accessToken");

  // console.log(location);
  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/invoice/detail", {
          params: { invoiceId: state.invoiceId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Invoice detail --------->", result.data);
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Салбарын нэр",
    "Компьютерын тоо",
    "Төлбөр",
    // "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <BackButton />
      <DetailTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
    </VStack>
  );
};

export default InvoiceDetail;
