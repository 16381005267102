import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  Tag,
  TagLabel,
  MenuItem,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import DeleteModal from "../../../components/modal/DeleteModal";
import axios from "../../../utils/axios";
import Cookies from "js-cookie";
import { BiCopy } from "react-icons/bi";
// import DeleteMember from "../../component/member.js/DeleteMember";

const BranchTable = ({ data, headerData, isLoading, setTrigger }) => {
  // console.log("Branch Table -->", data);
  const [isDisable, setIsDisable] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  const deleteHandler = () => {
    if (deleteId) {
      setIsDisable(true);
      axios
        .delete("/api/admin/", {
          data: {
            adminId: deleteId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setShowModal(false);
          setTrigger(true);
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"} className="primaryColor">
          {headerData ? (
            <Thead bg={"#F5F5F5"}>
              <Tr>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"}>{i + 1}</Text>
                    </Td>
                    {/* ------------ Нэр ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"}>
                        {e.branchName ? e.branchName : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Утас ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"} textTransform={"uppercase"}>
                        {e.phoneNo ? e.phoneNo : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Компьютерын тоо ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"} textTransform={"uppercase"}>
                        {e.computerCount || e.computerCount === 0
                          ? e.computerCount
                          : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Хаяг ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                    >
                      <Text variant={"table"} noOfLines={2}>
                        {e.address ? e.address : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Токен ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      py={"2"}
                    >
                      <HStack>
                        <Input
                          isReadOnly={true}
                          bg={"#F5F5F5"}
                          size={"sm"}
                          rounded={"none"}
                          fontWeight={"normal"}
                          fontSize={13}
                          color={"#B7B7B7"}
                          _focusVisible={{
                            outline: "none",
                          }}
                          value={e.accessToken}
                        />
                        <IconButton
                          colorScheme="green"
                          size="sm"
                          rounded={"sm"}
                          icon={<BiCopy />}
                          onClick={() => {
                            navigator.clipboard.writeText(e.accessToken);
                            if (!toast.isActive(id)) {
                              toast({
                                id,
                                duration: 2000,
                                position: "top",
                                status: "success",
                                description: "Амжилттай хууллаа",
                              });
                            }
                          }}
                        />
                      </HStack>
                    </Td>
                    {/* ------------ Цаг ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                    >
                      <Text
                        variant={"table"}
                        textTransform={"uppercase"}
                        noOfLines={2}
                      >
                        {e.createdAt ? e.createdAt.slice(0, 10) : "-"}
                      </Text>
                    </Td>
                    <Td w={"20"} py={"2"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          h={"8"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>
                        <MenuList
                          minW="24"
                          w={"max-content"}
                          p={"0"}
                          rounded={"none"}
                          shadow={"md"}
                        >
                          {/* <MenuItem
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            onClick={() => {
                              setDeleteId(e._id);
                              setShowModal(true);
                            }}
                          >
                            Устгах
                          </MenuItem> */}
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        isDisable={isDisable}
        deleteHandler={deleteHandler}
      />
    </VStack>
  );
};

export default BranchTable;
