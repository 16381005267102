import React, { useEffect, useState } from "react";
import { Input, VStack, useColorMode } from "@chakra-ui/react";
import axios from "../../utils/axios";
import Cookies from "js-cookie";
import CustomPagination from "../../components/pagination/CustomPagination";
import OrganizationTable from "./OrganizationTable";

const Organization = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const token = Cookies.get("accessToken");
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/organization/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("organization --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.theCount));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Үүсгэгч",
    "Байгууллагын регистр",
    "Нэр",
    "Салбарын тоо",
    "Гишүүнчлэл",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <Input
        placeholder="Хэрэглэгч хайх"
        bg={colorMode === "dark" ? "#000" : "#fff"}
        w={"xs"}
        rounded={"none"}
        fontSize={13}
        fontWeight={"normal"}
        color={"#000"}
        value={searchData}
        onChange={(e) => setSearchData(e.target.value)}
        _focusVisible={{
          outline: "none",
        }}
      />
      <OrganizationTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
      />
    </VStack>
  );
};

export default Organization;
