import React, { useContext, useState } from "react";
import {
  Flex,
  Box,
  Stack,
  Button,
  Input,
  InputGroup,
  Divider,
  Icon,
  InputLeftAddon,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { FiPhone } from "react-icons/fi";
import { VscKey } from "react-icons/vsc";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import AuthContext from "../../utils/request/Authentication";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const { loginHandler } = useContext(AuthContext);
  const { isLoading } = useContext(UserContext);
  // ----------------------------
  //       username: super
  //       password: qwer123@
  // ----------------------------

  const token = Cookies.get("accessToken");

  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Box
        className="primaryColor"
        rounded={"md"}
        boxShadow={"md"}
        p={8}
        w={"xs"}
      >
        <Stack spacing={4}>
          <InputGroup
            className="secondaryColor"
            p={"1"}
            shadow={"md"}
            rounded={"md"}
            borderLeftWidth={"medium"}
            borderLeftColor={"#0066CC"}
          >
            <InputLeftAddon
              border={"none"}
              bg={"transparent"}
              pointerEvents="none"
            >
              <Icon as={FiPhone} color="gray.300" />
            </InputLeftAddon>
            <Divider
              orientation="vertical"
              height="20px"
              alignSelf={"center"}
              borderColor={"#ddd"}
            />
            <Input
              variant={"primary"}
              border={"none"}
              bg={"transparent"}
              placeholder="Нэвтрэх нэр"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </InputGroup>
          <InputGroup
            className="secondaryColor"
            p={"1"}
            shadow={"md"}
            rounded={"md"}
            borderLeftWidth={"medium"}
            borderLeftColor={"#0066CC"}
          >
            <InputLeftAddon
              border={"none"}
              bg={"transparent"}
              pointerEvents="none"
            >
              <Icon as={VscKey} color="gray.300" />
            </InputLeftAddon>
            <Divider
              orientation="vertical"
              height="20px"
              alignSelf={"center"}
              borderColor={"#ddd"}
            />
            <Input
              variant={"primary"}
              border={"none"}
              bg={"transparent"}
              placeholder="Нууц үг"
              type={show ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (username && password) {
                  if (e.key === "Enter") {
                    loginHandler(username, password);
                  }
                }
              }}
            />
            <InputRightElement
              mt={"1"}
              mr={"1"}
              children={
                <Icon
                  as={show ? AiOutlineEye : AiOutlineEyeInvisible}
                  w={5}
                  h={5}
                  color={"gray.500"}
                  onClick={() => setShow(!show)}
                  cursor={"pointer"}
                />
              }
            />
          </InputGroup>
          <Stack spacing={6}>
            <Button
              variant={"primary"}
              onClick={() => loginHandler(username, password)}
              isDisabled={!isLoading && username && password ? false : true}
            >
              {isLoading && <Spinner mr={2} size="sm" />}
              Нэвтрэх
            </Button>
            <Button variant={"link"}>Нууц үг мартсан?</Button>
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
}
