import React, { useState } from "react";
import "rc-pagination/assets/index.css";
import localeInfo from "./lang";
import Pagination from "rc-pagination";

const CustomPagination = ({
  currentPage,
  setCurrentPage,
  totalCount,
  setTrigger,
}) => {
  const changePage = (current, pageSize) => {
    // console.log("onChange:current=", current);
    setCurrentPage(current);
    setTrigger(true);
  };

  return (
    <Pagination
      // showQuickJumper
      pageSize={30}
      current={currentPage}
      onChange={changePage}
      total={totalCount}
      locale={localeInfo}
      style={{
        alignSelf: "flex-end",
      }}
    />
  );
};

export default CustomPagination;
