import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../../utils/axios";
import CustomPagination from "../../../components/pagination/CustomPagination";
import ListTable from "./ListTable";
import { BiPlus } from "react-icons/bi";
import CustomInput from "../../../components/input/CustomInput";
import CustomSelect from "../../../components/select/CustomSelect";
import PasswordInput from "../../../components/input/PasswordInput";

const AdminList = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearData = () => {
    setUsername("");
    setPassword("");
    setRole("");
  };

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/admin/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("admin list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.theCount));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const submitHandler = () => {
    if (username && password && role) {
      setIsDisable(true);
      axios
        .post(
          "/api/admin/",
          {
            username: username,
            password: password,
            role: role,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            clearData();
            onClose();
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const headerData = ["#", "Хэрэглэгчийн нэр", "Цол", "Төлөв", "Үйлдэл"];

  const options = [
    {
      name: "super",
    },
    {
      name: "admin",
    },
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack
        className="primaryColor"
        px={"8"}
        py={"2"}
        border={"1px"}
        borderColor={"#ddd"}
        onClick={onOpen}
        cursor={"pointer"}
      >
        <Icon as={BiPlus} />
        <Text fontSize={13} fontWeight={"medium"}>
          Хэрэглэгч нэмэх
        </Text>
      </HStack>
      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Хэрэглэгч нэмэх</ModalHeader>
          <ModalCloseButton
            _focus={{
              outline: "none",
            }}
          />
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"} spacing={2}>
              {/* -------------- Нэр ---------------- */}
              <CustomInput
                title={"Нэвтрэх нэр"}
                value={username}
                onChange={setUsername}
              />
              {/* -------------- Нууц үг ---------------- */}
              <PasswordInput
                title={"Нууц үг"}
                value={password}
                onChange={setPassword}
              />
              {/* -------------- Цол ---------------- */}
              <CustomSelect
                title={"Цол"}
                value={role}
                onChange={setRole}
                options={options}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={15}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                clearData();
              }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={15}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              isDisabled={
                username && password && role && !isDisable ? false : true
              }
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default AdminList;
