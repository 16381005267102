import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Icon,
  Spinner,
  Center,
  VStack,
} from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";

const DetailTable = ({ data, headerData, isLoading, setTrigger }) => {
  return (
    <VStack
      w={"full"}
      minH={data.length === 0 ? "20" : "max-content"}
      pos={"relative"}
      spacing={0}
    >
      <TableContainer w={"full"}>
        <Table border={"1px"} borderColor={"#ECECEC"}>
          {headerData ? (
            <Thead bg={"#F5F5F5"}>
              <Tr>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={13}
                    >
                      <Text>{i + 1}</Text>
                    </Td>
                    {/* ------------ Салбарын нэр ------------ */}
                    <Td
                      minW={"40"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      textTransform={"capitalize"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.branchName ? e.branchName : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Компьютерын тоо ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      textTransform={"capitalize"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.computerCount ? e.computerCount : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Төлбөр ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.total || e.total === 0
                          ? e.total.toLocaleString() + "₮"
                          : "-"}
                      </Text>
                    </Td>
                    {/* <Td w={"20"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          h={"8"}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focusVisible={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>
                        <MenuList
                          minW="24"
                          w={"max-content"}
                          p={"0"}
                          rounded={"none"}
                          shadow={"md"}
                        >
                          <MenuItem
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            onClick={() =>
                              history.push("/finance/report/detail", {
                                reportId: e._id,
                                balance: e.totalMoney,
                                admin: e.createdBy,
                                description: e.description,
                              })
                            }
                          >
                            Төлөх
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td> */}
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default DetailTable;
