import React, { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { HStack, Spinner, Stack, VStack } from "@chakra-ui/react";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";

const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();
  const accessToken = Cookie.get("accessToken");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);

  return (
    <Suspense fallback={<Spinner />}>
      <HStack w={"100vw"} h={"100vh"} spacing={0}>
        <Sidebar />
        <VStack w={"full"} h={"full"} minH={"100vh"} spacing={0}>
          <Header />
          <Stack w={"full"} p={"6"}>
            {element}
          </Stack>
        </VStack>
      </HStack>
    </Suspense>
  );
};

export default PrivateRoute;
