import React from "react";
import { Input, Text, VStack } from "@chakra-ui/react";

const CustomInput = ({ title, value, onChange, type, onKeyDown }) => {
  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={0}>
      <Text fontWeight={"medium"}>{title + ":"}</Text>
      <Input
        placeholder="-"
        w={"full"}
        //   size={"sm"}
        //   rounded={"none"}
        fontWeight={"normal"}
        fontSize={15}
        color={"#000"}
        type={type === "number" ? "number" : "text"}
        _focusVisible={{
          outline: "none",
          borderColor: "#0066CC",
        }}
        _hover={{
          outline: "none",
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </VStack>
  );
};

export default CustomInput;
