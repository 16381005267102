import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;

  // console.log(location);

  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const breadcrumbs = pathSegments.map((segment, index) => {
    const url = `/${pathSegments.slice(0, index + 1).join("/")}`;
    return (
      <BreadcrumbItem
        key={segment}
        isCurrentPage={segment === "settings" ? true : false}
      >
        <BreadcrumbLink
          href={url}
          // onClick={() => navigate(url)}
        >
          {segment === "organization"
            ? "Байгууллага"
            : segment === "settings"
            ? "Тохиргоо"
            : segment === "admin"
            ? "Хэрэглэгч"
            : segment === "profile"
            ? "Профайл"
            : segment === "detail"
            ? "Дэлгэрэнгүй"
            : segment}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  });

  return (
    <Breadcrumb
      spacing={2}
      separator={<FiChevronRight color="gray.100" />}
      fontSize={13}
      fontWeight={"normal"}
    >
      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink>Хуудас</BreadcrumbLink>
      </BreadcrumbItem>
      {breadcrumbs.length === 0 ? (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Хяналтын самбар</BreadcrumbLink>
        </BreadcrumbItem>
      ) : (
        breadcrumbs
      )}
    </Breadcrumb>
  );
};

export default BreadCrumb;
