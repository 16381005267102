import React, { useEffect, useState } from "react";
import { VStack, useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../../utils/axios";
import CustomPagination from "../../../components/pagination/CustomPagination";
import ListTable from "./ListTable";

const InvoiceList = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/invoice/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Invoice list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.dataLength));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Байгууллага",
    "Нэхэмжлэлийн дугаар",
    "Салбар",
    "Хугацаа",
    "Үнэ",
    "Төлөв",
    "И-баримт",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
      />
    </VStack>
  );
};

export default InvoiceList;
