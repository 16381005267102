import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },

  styles: {
    global: (props) => ({
      body: {
        // bg: mode("#fff", "#000")(props),
        bg: mode("#FBFBFB", "#181E2B")(props),
        color: mode("#000", "#fff")(props),
        // bg: mode("#F5F5F5", "#181E2B")(props),
      },
      "*::placeholder": {
        color: mode("gray.400", "gray.600")(props),
        // color: mode('gray.400', 'whiteAlpha.400')(props),
      },
      // ".chakra-stack": {
      //   bg: mode("rgba(255,255,255,.5)", "rgba(255,255,255,.5)")(props),
      // },
      ".mainColor": {
        bg: mode("#0066CC", "#000")(props),
      },
      ".primaryColor": {
        bg: mode("#fff", "#232B38")(props),
      },
      ".secondaryColor": {
        bg: mode("#fff", "#0E0E0E")(props),
      },
      // '*, *::before, &::after': {
      //   borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      //   wordWrap: 'break-word',
      // },
    }),
  },
  components: {
    Text: {
      baseStyle: {
        fontWeight: "normal",
        fontSize: 15,
      },
      variants: {
        primary: (props) => ({
          color: mode("#000", "fff")(props),
        }),
        title: (props) => ({
          fontWeight: "medium",
          fontSize: 17,
          color: mode("#000", "#fff")(props),
        }),
        table: (props) => ({
          fontWeight: "normal",
          fontSize: 13,
          color: mode("#000", "#fff")(props),
        }),
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "medium",
        fontSize: 11,
      },
      variants: {
        primary: (props) => ({
          fontSize: 15,
          bg: "#0066CC",
          color: "#fff",
          _hover: {
            bg: "#1F79D4",
          },
          _disabled: {
            bg: "#0066CC",
            pointerEvents: "none",
            _hover: {
              bg: "#0066CC",
            },
            "&:hover": {
              bg: "#0066CC",
            },
          },
        }),
        link: (props) => ({
          fontSize: 15,
        }),
        ghost: (props) => ({
          fontSize: 15,
          _hover: {
            bg: "transparent",
          },
        }),
      },
    },
    Input: {
      baseStyle: {
        fontWeight: "medium",
        fontSize: 11,
      },
      variants: {
        primary: (props) => ({
          fontSize: 15,
          bg: "#0066CC",
          color: "#fff",
          _hover: {
            bg: "#1F79D4",
          },
          _focusVisible: {
            outline: "none",
          },
        }),
        link: (props) => ({
          fontSize: 15,
        }),
      },
    },
  },
});

export default theme;
