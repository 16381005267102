import React from "react";
import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import { BiMoon, BiSun } from "react-icons/bi";

const ToggleColorMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    // <IconButton
    //   // pos={"absolute"}
    //   // top={"10"}
    //   // right={"10"}
    //   icon={
    //     colorMode === "dark" ? (
    //       <Icon as={BiSun} w={"5"} h={"5"} />
    //     ) : (
    //       <Icon as={BiMoon} w={"5"} h={"5"} />
    //     )
    //   }
    //   onClick={() => toggleColorMode()}
    // />
    <Icon
      transition={"ease-in-out .3s"}
      as={colorMode === "dark" ? BiSun : BiMoon}
      // _hover={{
      //   color: "orange",
      // }}
      boxSize={"5"}
      onClick={() => toggleColorMode()}
      cursor={"pointer"}
    />
  );
};

export default ToggleColorMode;
