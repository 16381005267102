import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserContext from "./context/UserContext";
import PrivateRoute from "./utils/route/PrivateRoute";
import Login from "./pages/auth/Login";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/dashboard/Dashboard";
import Organization from "./pages/organization/Organization";
import Profile from "./pages/profile/Profile";
import AdminList from "./pages/settings/admin/AdminList";
import OrganizationDetail from "./pages/organization/detail/OrganizationDetail";
import InvoiceList from "./pages/finance/invoice/InvoiceList";
import InvoiceDetail from "./pages/finance/invoice/InvoiceDetail";

const App = () => {
  const context = useContext(UserContext);

  return (
    <BrowserRouter>
      <Routes>
        {/* --------------------------
                Public Routes
        -------------------------- */}
        <Route path="/login" element={<Login />} />

        {/* --------------------------
                Protected Routes
        -------------------------- */}
        <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
        <Route
          path="/organization"
          element={<PrivateRoute element={<Organization />} />}
        />
        <Route
          path="/organization/detail"
          element={<PrivateRoute element={<OrganizationDetail />} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} />}
        />
        <Route
          path="/settings/admin"
          element={<PrivateRoute element={<AdminList />} />}
        />
        <Route
          path="/finance/invoice"
          element={<PrivateRoute element={<InvoiceList />} />}
        />
        <Route
          path="/finance/invoice/detail"
          element={<PrivateRoute element={<InvoiceDetail />} />}
        />

        {/* --------------------------
                  Not Found
        -------------------------- */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

{
  /* <Route
  path="/"
  element={
    <PrivateLayout>
      <PrivateRoute index element={<Dashboard />} />
      <PrivateRoute path="/test" element={<Test />} />
    </PrivateLayout>
  }
/>; */
}
