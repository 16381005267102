import React, { useEffect, useState } from "react";
import { Text, VStack } from "@chakra-ui/react";
import axios from "../../../utils/axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import BranchTable from "./BranchTable";
import CustomPagination from "../../../components/pagination/CustomPagination";
import BackButton from "../../../components/button/BackButton";

const OrganizationDetail = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const { state } = location;
  const token = Cookies.get("accessToken");

  useEffect(() => {
    if (trigger && state._id) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/branch/", {
          params: { organizationId: state._id, page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("branchs --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.theCount));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Салбарын нэр",
    "Утас",
    "Компьютерын тоо",
    "Хаяг",
    "Токен",
    "Үүссэн огноо",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <BackButton />
      {/* <Text>{state._id}</Text> */}
      <BranchTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
      />
    </VStack>
  );
};

export default OrganizationDetail;
