// import React from "react";
// import { Button } from "@chakra-ui/react";
// import Cookies from "js-cookie";
// import { Navigate, useNavigate } from "react-router-dom";

// const Dashboard = () => {
//   const token = Cookies.get("accessToken");
//   const navigate = useNavigate();

//   if (!token) {
//     return <Navigate to="/login" />;
//   }

//   return (
//     <div>
//       <Button onClick={() => navigate("/test")}>TEST</Button>
//     </div>
//   );
// };

// export default Dashboard;

import React from "react";
import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Framer from "./Framer";
import { FiInfo } from "react-icons/fi";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Text>Dashboard</Text>
      <Button onClick={() => navigate("/test")}>TEST</Button>
      <HStack
        w={"80vw"}
        h={"20vh"}
        bg={"#fff"}
        spacing={10}
        align={"center"}
        justify={"center"}
        p={"10"}
      >
        <Framer>
          <Icon as={FiInfo} w={"10"} h={"10"} bg={"Red"} cursor={"pointer"} />
        </Framer>
        <Framer>
          <Icon as={FiInfo} w={"10"} h={"10"} bg={"Red"} cursor={"pointer"} />
        </Framer>
        <Framer>
          <Icon as={FiInfo} w={"10"} h={"10"} bg={"Red"} cursor={"pointer"} />
        </Framer>
        <Framer>
          <Icon as={FiInfo} w={"10"} h={"10"} bg={"Red"} cursor={"pointer"} />
        </Framer>
      </HStack>
    </div>
  );
};

export default Dashboard;
