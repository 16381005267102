import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  Tag,
  TagLabel,
  MenuItem,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  ModalBody,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../../utils/axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const ListTable = ({ data, headerData, isLoading, setTrigger }) => {
  // console.log("Invoice Table -->", data);
  const [isDisable, setIsDisable] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const submitHandler = () => {
    if (updateId) {
      setIsDisable(true);
      axios
        .put(
          "/api/invoice/change_status",
          {
            invoiceId: updateId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setUpdateId("");
            onClose();
            setTrigger(true);
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisable(false);
        });
    }
  };

  const ebarimtHandler = () => {
    if (updateId) {
      setLoader(true);
      axios
        .put(
          "/api/invoice/ebarimt",
          {
            invoiceId: updateId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
          }
          setShowModal(false);
          setUpdateId("");
          setLoader(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setLoader(false);
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"} className="primaryColor">
          {headerData ? (
            <Thead bg={"#F5F5F5"}>
              <Tr>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"}>{i + 1}</Text>
                    </Td>
                    {/* ------------ Байгууллага ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"}>
                        {e.organizationId?.organizationName
                          ? e.organizationId?.organizationName
                          : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Нэхэмжлэлийн дугаар ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"}>{e._id ? e._id : "-"}</Text>
                    </Td>
                    {/* ------------ Салбар ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"} textTransform={"uppercase"}>
                        {e.branches ? e.branches : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Хугацаа ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"} textTransform={"uppercase"}>
                        {e.startDate && e.endDate
                          ? e.startDate.slice(0, 10) +
                            " / " +
                            e.endDate.slice(0, 10)
                          : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Үнэ ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text variant={"table"} textTransform={"uppercase"}>
                        {e.total || e.total === 0
                          ? e.total.toLocaleString() + "₮"
                          : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Төлөв ------------ */}
                    <Td
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Tag
                        size={"sm"}
                        variant="solid"
                        colorScheme={e.isPaid ? "green" : "red"}
                      >
                        <TagLabel fontSize={13}>
                          {e.isPaid ? "Төлөгдсөн" : "Төлөгдөөгүй"}
                        </TagLabel>
                      </Tag>
                    </Td>
                    {/* ------------ И-баримт ------------ */}
                    <Td
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      {e.ebarimtGenerated ? (
                        <Tag size={"sm"} variant="solid" colorScheme={"green"}>
                          <TagLabel fontSize={13}>Шивэгдсэн</TagLabel>
                        </Tag>
                      ) : (
                        <Button
                          variant={"link"}
                          fontSize={15}
                          fontWeight={"medium"}
                          textUnderlineOffset={"initial"}
                          isLoading={loader && e._id === updateId}
                          loadingText="Уншиж байна"
                          onClick={() => {
                            setUpdateId(e._id);
                            setShowModal(true);
                          }}
                          isDisabled={e.isPaid === false ? true : false}
                        >
                          Шивэх
                        </Button>
                      )}
                    </Td>
                    <Td w={"20"} py={"2"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          h={"8"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>
                        <MenuList
                          minW="24"
                          w={"max-content"}
                          p={"0"}
                          rounded={"none"}
                          shadow={"md"}
                        >
                          <MenuItem
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            onClick={() =>
                              navigate("/finance/invoice/detail", {
                                state: {
                                  invoiceId: e._id,
                                  isPaid: e.isPaid,
                                  ebarimt: e.ebarimtObject,
                                },
                              })
                            }
                          >
                            Дэлгэрэнгүй
                          </MenuItem>
                          <MenuItem
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            onClick={() => {
                              setUpdateId(e._id);
                              onOpen();
                            }}
                            isDisabled={e.isPaid}
                          >
                            Засах
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      <Modal
        isCentered
        onClose={!showModal}
        isOpen={showModal}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Итгэлтэй байна уу?</ModalHeader>
          <ModalBody>
            <Text>
              Та "Тийм" товчийг дарснаар И-Баримт шивэгдсэн төлөвт шилжиж дахин
              засварлах боломжгүй болохыг анхаарна уу!
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={15}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                setShowModal(false);
                setUpdateId("");
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={15}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={ebarimtHandler}
              isDisabled={updateId && !loader ? false : true}
            >
              {loader ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Итгэлтэй байна уу?</ModalHeader>
          <ModalCloseButton
            _focus={{
              outline: "none",
            }}
          />
          <ModalBody>
            <Text>
              Та "Тийм" товчийг дарснаар төлөв өөрчлөгдөж дахин засварлах
              боломжгүй болохыг анхаарна уу!
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={15}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setUpdateId("");
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={15}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              isDisabled={updateId && !isDisable ? false : true}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ListTable;
