import { FiSettings } from "react-icons/fi";
import { RiHome3Line, RiOrganizationChart } from "react-icons/ri";
import { AiOutlineCalculator, AiOutlineUser } from "react-icons/ai";

export const navData = [
  {
    title: "Хяналтын самбар",
    toLink: "/",
    icon: RiHome3Line,
  },
  {
    title: "Байгууллага",
    toLink: "/organization",
    icon: RiOrganizationChart,
  },
  {
    title: "Тохиргоо",
    toLink: "/settings",
    icon: FiSettings,
    subTitle: [
      {
        title: "Хэрэглэгч",
        toLink: "/settings/admin",
      },
      {
        title: "Мэдэгдэл",
        toLink: "/settings/user",
      },
    ],
  },
  {
    title: "Санхүү",
    toLink: "/finance",
    icon: AiOutlineCalculator,
    subTitle: [
      {
        title: "Нэхэмжлэл",
        toLink: "/finance/invoice",
      },
    ],
  },
];
