import React from "react";
import { HStack, Icon, Text } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    // <Center
    //   py={"1"}
    //   borderRadius="md"
    //   cursor="pointer"
    //   onClick={() => navigate(-1)}
    // >
    //   <Icon as={HiOutlineArrowNarrowLeft} color={"#000"} w={5} h={5} />
    //   <Text fontSize={13} color={"#000"} fontWeight={"normal"} ml={"2"}>
    //     Буцах
    //   </Text>
    // </Center>
    <HStack
      className="primaryColor"
      spacing={1}
      pl={"2"}
      pr={"4"}
      py={"2"}
      border={"1px"}
      borderColor={"#ddd"}
      onClick={() => navigate(-1)}
      cursor={"pointer"}
    >
      <Icon as={RiArrowLeftSLine} w={5} h={5} />
      <Text fontSize={13} fontWeight={"medium"}>
        Буцах
      </Text>
    </HStack>
  );
};

export default BackButton;
